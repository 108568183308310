import React, { useState } from "react";
import { PaymentForm } from "./PaymentForm";
import { CollapsePanel } from "components/molecules/CollapsePanel/CollapsePanel";
import { useTranslation } from "react-i18next";
import barion from "resources/barion.png";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getPaymentState, postPayment } from "api/payment/payment";
import { useQuery } from "react-query";
import { GET_SITE_KEY } from "../../../utils/utils";
import ReCAPTCHA from "react-google-recaptcha";

export const PaymentPageTemplate = ({ pageData }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(null);
  const recaptchaRef = React.useRef();

  const paymentId = new URLSearchParams(window.location.search).get(
    "paymentId"
  );

  const { data: paymentState } = useQuery(
    ["paymentState", paymentId],
    getPaymentState,
    {
      enabled: Boolean(paymentId),
    }
  );

  const pay = async () => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    let response = await postPayment({ data: formData, token: token });
    if (response.data.gatewayUrl) {
      window.location.href = response.data.gatewayUrl;
    }
  };

  return (
    <div className={"PaymentPageTemplate"}>
      <h2>{pageData.address}</h2>
      <CollapsePanel title={t("payment.generalInformant")}>
        {pageData.content != null && (
          <>
            {pageData.content[0] && (
              <div
                key={1}
                dangerouslySetInnerHTML={{ __html: pageData.content[0].text }}
              />
            )}
            {pageData.content[1] && (
              <div
                key={2}
                dangerouslySetInnerHTML={{ __html: pageData.content[1].text }}
              />
            )}
          </>
        )}
      </CollapsePanel>
      {pageData.content[2] && (
        <div
          className={"barion-warning"}
          key={3}
          dangerouslySetInnerHTML={{ __html: pageData.content[2].text }}
        />
      )}
      <PaymentForm setFormData={setFormData} />
      {paymentState && (
        <div className={"payment-state"}>
          {paymentState.paymentSuccess && <h3>Sikeres befizetés</h3>}
          {paymentState.paymentSuccess === false && (
            <h3>Sikertelen befizetés</h3>
          )}
        </div>
      )}

      <div className={"stars"}>
        <small>{t("payment.oneStarPayment")}</small> <br />
        <br />
        <small>{t("payment.twoStar")}</small>
      </div>
      {formData != null && (
        <div className={"payment-form"}>
          <h3>{t("payment.amountDue") + " " + formData.amountDue + " Ft"}</h3>
          <Button color={"primary"} onClick={pay}>
            {t("payment.pay")}
            <ArrowForwardIosIcon />
          </Button>
        </div>
      )}
      <div className={"barion"}>
        <img src={barion} alt={"barion"} />
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={GET_SITE_KEY()} size="invisible" />
    </div>
  );
};
